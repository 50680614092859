import React from 'react'
import { Params, serializeQueryString, parseQueryParams } from './Routing'
import { fold } from '../../collections/Either'
import { v4 as uuid } from 'uuid'
import { removeArrayElement } from '../../helpers/Collections'
import { capitalizeFirstLetter } from '../../helpers/StringHelper'

/***************************************
 *
 * Router is intentionally not located in the provider directory
 *
 * While it does use a Provider/Consumer pattern via context
 *
 * It is not meant to be used MULTIPLE times. There should be exactly one router in the application
 *
 * ***************************************/

type Route = string

type HandlePageChange = (route: Route, params?: Params, isPopState?: boolean) => void

type PreNavigationHookHandle = string
interface RouterContextProps {
  readonly handlePageChange: HandlePageChange
  readonly queryParams: Params
  readonly currentPage: string
  readonly pathPrefix: string
  readonly registerPreNavigationHook: (hook: PreNavigationHook) => PreNavigationHookHandle
  readonly unregisterPreNavigationHook: (handle: PreNavigationHookHandle) => void
}

const noRouterMountedError = (functionName: string) => () =>
  console.log(
    `${functionName} was called without a router in scope. ` +
      'Ensure that there is a router mounted in the application before attempting to call "handlePageChange"!'
  )

/**
 * The router Context
 *
 * Intentionally private to this module!
 *
 * If you need to interract with the router, do so via the exported components found below!*
 */
const RouterContext = React.createContext<RouterContextProps>({
  handlePageChange: noRouterMountedError('handlePageChange'),
  queryParams: {},
  currentPage: '',
  registerPreNavigationHook: () => {
    noRouterMountedError('registerPreNavigationHook')()
    return 'No Handle!'
  },
  unregisterPreNavigationHook: noRouterMountedError('unregisterPreNavigationHook'),
  pathPrefix: '/',
})

export const useRouterContext = (): RouterContextProps => React.useContext(RouterContext)

/**
 * Provides a router context to an arbitrary component.
 * Intentionally private to this module!
 *
 * If you need to interract with the router, do so via the exported components found below!
 *
 * @param Component The component to provide a router context to
 */
function withRouterContext<OwnProps>(
  Component: React.ComponentType<OwnProps & RouterContextProps>
): React.ComponentClass<OwnProps> {
  class WithRouterContext extends React.Component<OwnProps> {
    constructor(props) {
      super(props)
    }
    render() {
      return (
        <RouterContext.Consumer>
          {routerContext => <Component {...routerContext} {...this.props}></Component>}
        </RouterContext.Consumer>
      )
    }
  }

  return WithRouterContext
}

interface RouterState {
  readonly currentPage: string
  readonly params: Params
  readonly routePrefix: string
  readonly leader: string
  readonly preNavigationHooks: ReadonlyArray<{
    handle: PreNavigationHookHandle
    hook: PreNavigationHook
  }>
}

interface RouterProps {
  readonly initialPage: string
  readonly initialParams?: Params
  readonly defaultPage: string
  readonly routePrefix?: string
  readonly leader?: string
  readonly pages: ReadonlyArray<string>
}

interface PreNavigationHook {
  renderPredicate: () => boolean
  prompt: () => string
}

/**
 * Application Router. Should have exactly one at top of hierarchy
 */
class Router extends React.Component<RouterProps, RouterState> {
  constructor(props: RouterProps) {
    super(props)
    const { routePrefix, leader, initialPage, initialParams } = props
    const lead = leader || '/'
    const prefixToUse = routePrefix && routePrefix.length > 0 ? routePrefix : ''
    this.state = {
      currentPage: initialPage,
      params: initialParams || {},
      routePrefix: prefixToUse,
      leader: lead,
      preNavigationHooks: [],
    }
  }

  componentDidMount() {
    const { leader, routePrefix } = this.props
    const shortPrefix = `${leader}${routePrefix}`
    const prefixPath = shortPrefix.endsWith('/') ? shortPrefix : `${shortPrefix}/`
    window.onpopstate = () => {
      const path = document.location.pathname
      const queryParams = parseQueryParams(document.location.search)
      const pathToNavigate = path.replace(prefixPath, '')
      this.handlePageChange(pathToNavigate, queryParams, true)
    }

    window.onbeforeunload = () => {
      const { preNavigationHooks } = this.state
      const result = preNavigationHooks.some(hook => hook.hook.renderPredicate())
      return result || undefined
    }
    document.title = `Dashboard | ${this.getPageTitle(this.state.currentPage)}`
  }

  componentDidUpdate() {
    document.title = `Dashboard | ${this.getPageTitle(this.state.currentPage)}`
  }

  // Make sure to clean up all window lifecycle hooks
  componentWillUnmount() {
    window.onpopstate = () => undefined
    window.onbeforeunload = () => undefined
  }

  getPageTitle(currentPage) {
    return this.generatePageTitle(currentPage)
  }

  generatePageTitle(currentPageRoute) {
    const pageArray = currentPageRoute.split('/')

    return this.removeHyphensFromTitle(pageArray).map(capitalizeFirstLetter).join(' | ')
  }

  removeHyphensFromTitle(pageArray) {
    return pageArray.map(page => {
      return page.replace(/-/g, ' ')
    })
  }

  handlePageChange: HandlePageChange = (route, params, isPopState) => {
    const { pages, defaultPage } = this.props
    const { preNavigationHooks, routePrefix, leader } = this.state
    const necessaryRenderHooks = preNavigationHooks.filter(hook => hook.hook.renderPredicate())
    // Always the last one, which we interpret to mean the most specific.
    const chosenHookPrompt: string | undefined =
      necessaryRenderHooks.length > 0
        ? necessaryRenderHooks[necessaryRenderHooks.length - 1].hook.prompt()
        : undefined

    // Allow navigation to halt when provided hooks via <Prompt>
    const proceed: boolean =
      chosenHookPrompt !== undefined ? window.confirm(chosenHookPrompt) : true
    if (proceed) {
      if (pages.indexOf(route) < 0) {
        if (!isPopState) {
          this.updateUrl(defaultPage, {}, routePrefix, leader)
        }
        this.setState({ currentPage: defaultPage, params: {} })
      } else {
        if (!isPopState) {
          this.updateUrl(route, params, routePrefix, leader)
        }
        this.setState({ currentPage: route, params: params || {} })
      }
    }
  }

  updateUrl(currentPage, params, routePrefix, leader) {
    const serializedParams = serializeQueryString(params)
    const queryStringPart: string = fold(
      serializedParams,
      () => '',
      res => (res.length > 0 ? `?${res}` : '')
    )
    window.history.pushState(
      '',
      '',
      `${leader}${routePrefix}/${currentPage.toLowerCase()}${queryStringPart}`
    )
  }

  registerPreNavigationHook = (hook: PreNavigationHook) => {
    const { preNavigationHooks } = this.state
    const nextHandle = uuid()
    const nextHooks = [...preNavigationHooks, { handle: nextHandle, hook }]
    if (nextHooks.length > 1) {
      console.warn(
        'There are multiple router navigation hooks present, created via <Prompt>. ' +
          'This is probably indicative of either multiple forms visible at the same time, ' +
          ' where we cannot determine which prompt to show, or incorrect nesting.'
      )
    }
    this.setState({ preNavigationHooks: nextHooks })
    return nextHandle
  }

  unregisterPreNavigationHook = (handle: PreNavigationHookHandle) => {
    const { preNavigationHooks } = this.state
    const nextHooks = removeArrayElement(
      preNavigationHooks,
      preNavigationHooks.findIndex(e => e.handle === handle)
    )
    this.setState({ preNavigationHooks: nextHooks })
  }

  render() {
    const { params, currentPage, leader, routePrefix } = this.state
    const { children } = this.props
    return (
      <RouterContext.Provider
        value={{
          handlePageChange: this.handlePageChange,
          queryParams: params,
          registerPreNavigationHook: this.registerPreNavigationHook,
          unregisterPreNavigationHook: this.unregisterPreNavigationHook,
          currentPage,
          pathPrefix: leader + routePrefix,
        }}
      >
        {children}
      </RouterContext.Provider>
    )
  }
}

export default Router

export interface LinkProps {
  readonly params?: Params
  readonly to: string
  readonly className?: string
  readonly onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

class LinkClass extends React.Component<LinkProps & RouterContextProps> {
  render() {
    const { to, params, children, className, handlePageChange, pathPrefix, onClick } = this.props
    const serializedParams = serializeQueryString(params || {})
    // Clean the routing prefix if it was presented to the <Link>, for ease of use
    const cleanedRoute = to.startsWith(`${pathPrefix}/`) ? to.slice(pathPrefix.length + 1) : to
    const renderRoute = `${pathPrefix}/${cleanedRoute}`
    const uri = fold(
      serializedParams,
      error => {
        console.error(error)
        return renderRoute
      },
      params => (params.length > 0 ? `${renderRoute}?${params}` : renderRoute)
    )
    return (
      <a
        href={uri}
        className={className}
        onClick={e => {
          e.preventDefault()
          handlePageChange(cleanedRoute, params || {})
          if (onClick) {
            onClick(e)
          }
        }}
      >
        {children}
      </a>
    )
  }
}

/**
 * A router-hooked link for navigating within the internal router context.
 *
 * For links that require full page reloads, you want vanilla <a>
 */
export const Link = withRouterContext<LinkProps>(LinkClass)

interface RedirectProps {
  readonly params?: Params
  readonly to: string
}

class RedirectClass extends React.Component<RedirectProps & RouterContextProps> {
  componentDidMount() {
    const { to, params, handlePageChange } = this.props

    handlePageChange(to, params)
  }
  render() {
    /**
     * An entirely nonvisual declarative element that exists only for lifecycle hook behaviour
     */
    return <></>
  }
}

/**
 * A router-hooked redirect for redirecting within the internal router context.
 */
export const Redirect = withRouterContext<RedirectProps>(RedirectClass)

interface PromptProps {
  when: boolean
  message: string
}
interface PromptState {
  handle?: PreNavigationHookHandle
}
class PromptClass extends React.Component<PromptProps & RouterContextProps, PromptState> {
  constructor(props) {
    super(props)
    this.state = {
      handle: undefined,
    }
  }
  componentDidMount() {
    const { registerPreNavigationHook } = this.props
    const renderPredicate = () => this.props.when
    const prompt = () => this.props.message
    // uses thunked accessors to be able to have access to up to date props as rerenders occur.
    const hook: PreNavigationHook = {
      renderPredicate,
      prompt,
    }
    const handle = registerPreNavigationHook(hook)
    this.setState({ handle })
  }

  componentWillUnmount() {
    const { unregisterPreNavigationHook } = this.props
    const { handle } = this.state
    if (handle !== undefined) {
      unregisterPreNavigationHook(handle)
    }
  }

  /**
   * An entirely nonvisual declarative element that exists only for lifecycle hook behaviour
   */
  render() {
    return <></>
  }
}

/**
 * A pre-navigation Prompt that will alert the user that they may be navigating away from
 * something they do not wish to.
 */
export const Prompt = withRouterContext<PromptProps>(PromptClass)

export interface RoutingState {
  readonly queryParams: Params
  readonly currentPage: string
}
/**
 * Provides a current location and params to a given Component
 *
 * Should NEVER provide any behavioural hooks into the router.
 *
 * This should be removed in future when a more robust and declarative pattern is implemented
 *
 * @param Component The component to provide the Routing State to
 */
export function withRoutingState<OwnProps>(
  Component: React.ComponentType<OwnProps & RoutingState>
): React.ComponentClass<OwnProps> {
  class WithRoutingState extends React.Component<OwnProps> {
    constructor(props) {
      super(props)
    }
    render() {
      return (
        <RouterContext.Consumer>
          {routerContext => (
            <Component
              currentPage={routerContext.currentPage}
              queryParams={routerContext.queryParams}
              {...this.props}
            ></Component>
          )}
        </RouterContext.Consumer>
      )
    }
  }

  return WithRoutingState
}

import queryString from 'query-string'

import { Either, left, right } from '../../collections/Either'

export interface Params {
  [key: string]: number |string | string[] | null | undefined
}

export const serializeQueryString = (params: Params): Either<Error, string> => {
  const serializedParams = queryString.stringify(params, {
    arrayFormat: 'bracket',
  })
  const isUsingUndefinedQueryParamsBehaviour = serializedParams.indexOf('object%20Object') >= 0
  return isUsingUndefinedQueryParamsBehaviour
    ? left(
        new Error(
          'Cannot serialize nested objects, as this behaviour has inconsistent behaviour and is unsupported by the parser'
        )
      )
    : right(serializedParams)
}

export const parseQueryParams = (paramString: string): Params => queryString.parse(paramString)

const domainRegex = /https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i
export const isExternalUrl = (url: string): Either<Error, boolean> => {
  const urlMatches = domainRegex.exec(url)
  const locationMatches = domainRegex.exec(location.href)
  if (urlMatches && locationMatches) {
    return right(urlMatches[1] !== locationMatches[1])
  } else {
    return left(
      new Error(
        `Invalid input supplied to isExternalUrl. Received ${url} which is not a valid url.`
      )
    )
  }
}

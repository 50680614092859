// A tagged type of Object which contains an element of type L
// Where L can be any type
export interface Left<L> {
  tag: 'left'
  element: L
}

// A tagged type of Object which contains an element of type R
// Where R can be any type
export interface Right<R> {
  tag: 'right'
  element: R
}

/**
 *  A tagged type of object which contains a tag field as defined
 * in the Left and Right interfaces
 * It then contains an `element` field which contains an element of the appropriate type
 * according to the Left or Right tag and type definition
 */
export type Either<L, R> = Left<L> | Right<R>

/**
 *  Alias to Either with explicit numeration
 */
export type Either2<L, R> = Either<L, R>

/**
 * Alias to an Either where the Right contains another Either.
 * Essentially a disjunction between 3 types: L \/ RL \/ RR
 */
export type Either3<L, RL, RR> = Either<L, Either<RL, RR>>

/**
 * A type alias for an Either which contains nothing in its left side
 */
export type Maybe<T> = Either<void, T>

/**
 * Fold an Either<L, R> into a common result type T
 * @param either an Either<L, R> to compose into a common result T
 * @param fl a function from L to T to handle the Left case
 * @param fr a function from R to T to handle the Right case
 */
export function fold<L, R, T>(either: Either<L, R>, fl: (l: L) => T, fr: (r: R) => T) {
  switch (either.tag) {
    case 'left':
      return fl(either.element)
    case 'right':
      return fr(either.element)
  }
}

/**
 * A convenience wrapper for the necessary nested fold when dealing with an Either3
 * @param either an Either3 to compose back into some common result T
 * @param fl a function from L to T to handle the Left case
 * @param frl a function from RL to T to handle the nested middle case (A Right containing a Left)
 * @param frr a function from RR to T to handle the nested right case (A Right containing a Right)

 */
export function fold3<L, RL, RR, T>(
  either: Either3<L, RL, RR>,
  fl: (l: L) => T,
  frl: (rl: RL) => T,
  frr: (rr: RR) => T
) {
  return fold(either, fl, r => fold(r, frl, frr))
}

/**
 * Constructs a Left from a given value
 * A simple type constructor and a convenience for writing the Object literal
 * @param l An element to insert into a Left and whose type becomes that Left's inner type
 */
export function left<L>(l: L): Either<L, never> {
  return { tag: 'left', element: l }
}

/**
 * Constructs a Right from a given value
 * A simple type constructor and a convenience for writing the Object literal
 * @param r An element to insert into a Right and whose type becomes that Right's inner type
 */
export function right<R>(r: R): Either<never, R> {
  return { tag: 'right', element: r }
}

/**
 * This is the Left-Biasing version of the function, where lefts are the first elements of the list
 * @param ls A list of items that will become Lefts
 * @param rs A list of items that will become Rights
 */
export function toUnionList<L, R>(
  ls: ReadonlyArray<L>,
  rs: ReadonlyArray<R>
): ReadonlyArray<Either<L, R>> {
  return [...ls.map(left), ...rs.map(right)]
}

/**
 * This is the Right-Biasing version of the function, where Rights are the first elements of the list
 * @param ls A list of items that will become Lefts
 * @param rs A list of items that will become Rights
 */
export function toUnionListRightBias<L, R>(
  ls: ReadonlyArray<L>,
  rs: ReadonlyArray<R>
): ReadonlyArray<Either<L, R>> {
  return [...rs.map(right), ...ls.map(left)]
}

/**
 * Traverse an Array returning a New Array containing only the Left elements
 * @param es A list of eithers
 */
export function extractLefts<L, R>(es: ReadonlyArray<Either<L, R>>): ReadonlyArray<L> {
  return es.reduce<ReadonlyArray<L>>(
    (acc, elem) =>
      fold(
        elem,
        l => [...acc, l],
        _ => acc
      ),
    []
  )
}

/**
 * Traverse an Array returning a New Array containing only the Right elements
 * @param es A list of eithers
 */
export function extractRights<L, R>(es: ReadonlyArray<Either<L, R>>): ReadonlyArray<R> {
  return es.reduce<ReadonlyArray<R>>(
    (acc, elem) =>
      fold(
        elem,
        _ => acc,
        r => [...acc, r]
      ),
    []
  )
}
